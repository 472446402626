.App {
  text-align: center;
}

.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 400px;
}

.logo {
  color: white;
}

